<template>
  <!-- 公告列表 -->
  <div :class="{'noticeAnnouncement':true, 'rtop': $route.name == 'homePageList'}"
    :style="[{'minHeight':$route.name == 'homePageList'||$route.name == 'homePageDetails'?'70vh':'100%'}]"
  >
  <!-- <div :class="{'noticeAnnouncement':true, 'rtop': $route.name == 'homePageList'}"
    :style="[{'height':$route.name == 'homePageList'?'calc(100% - 105px)':($route.name == 'homePageDetails'?'calc(80vh - '+(44)+'px)':'100%')}]"
  > -->
  <!-- <div :class="['noticeAnnouncement',]"
    :style="[{'height':$route.name == 'homePageList'?'calc(100% - 44px)':($route.name == 'homePageDetails'?'calc(100% - '+(44)+'px) !important':'100%')}]"
  > -->
    <template v-if="$route.name == 'noticeAnnouncement'||$route.name == 'homePageList'">
      <div class="notice-cont">
        <div class="noitce-item" v-for="(item, index) in noticeList" :key="index">
          <div class="n-cont">
            <div :class="['n-point', item == 2 ? 'h-point' : '']"></div>
            <span>{{$way.timestampReturnDate(item.publisherTime, 'dateYMD','/')}}</span>
          </div>
          <div class="n-title" @click="jumpDetail(item)">{{item.newTitle}}</div>
          <div class="n-main" @click="jumpDetail(item)">{{item.content}}</div>
          <div class="n-share">
            <div><img src="@/assets/img/home/view.png" alt="" srcset=""/>{{item.views||0}}</div>
            <!-- <div @click="showShare=!showShare"><img src="@/assets/img/home/share.png" alt="" srcset=""/>分享</div> -->
          </div>
        </div>
      </div>
      <van-share-sheet v-model:show="showShare" :options="shareList"  @select="handleShare" />
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
export default {
  name: 'noticeAnnouncement',
  data() {
    return {
      noticeList: [],
      showShare:false,
      shareList: [
        {
          name: '微信',
          icon: 'https://fastly.jsdelivr.net/npm/@vant/assets/custom-icon-fire.png',
        },
        {
          name: '朋友圈',
          icon: 'https://fastly.jsdelivr.net/npm/@vant/assets/custom-icon-light.png',
        },
        {
          name: 'QQ',
          icon: 'https://fastly.jsdelivr.net/npm/@vant/assets/custom-icon-water.png',
        },
      ],
      footerH: 0,
      childMsg: {
        current: 1,
        size: 10,
        total: 0,
      },
    }
  },
  watch: {
    // '$store.state.isGetToken'(val) {
    //   if(val) {
    //     this.studentNoticesList();
    //   }
    // }
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.name == 'homePageList') {
          console.log('query',newVal.query);
          if (newVal.query.type || newVal.query.type==0) {
            console.log('object');
            this.getSchoolPage()
          } else {
          }
        }
      }
    }
  },
  created() {
  },
  methods: {
    studentNoticesList() {
      this.$request.studentNotices().then(res => {
        if(res.data.code == 0) {
          this.noticeList = res.data.data;
        }
      })
    },
    // 分享事件
    handleShare(a){
      console.log(a);
    },
    // 跳转通知详情页
    jumpDetail(row) {
      let name = 'noticeDetails'
      let type = null
      let style = null
      if (this.$route.query.type || this.$route.query.type==0) {
        name = 'homePageDetails'
        type = this.$route.query.type
        style = 'h5'
      }
      this.$router.push({name: name, query: {
        id: row.id,
        style: style
      }})
    },
    // 获取官网新闻、公告列表
    getSchoolPage() {
      let params = {
        tenantId: sessionStorage.getItem('tenant_id'),
        page: this.childMsg.current,
        newType: this.$route.query.type,
        pageSize: this.childMsg.size
      }
      this.$request.getSchoolPage(params).then((res) => {
        // this.childMsg.size = res.data.data.size
        // this.childMsg.current = res.data.data.current
        // this.childMsg.total = res.data.data.total
        // this.news = res.data.data.records
        if (res.data.code == 0) {
          if (res.data.data.records.length > 0) {
            this.noticeList = res.data.data.records
            // alert('getList2: ' + this.messageList)
          } else {
            // this.noData = true
          }
        }
      }).catch((err) => {
        
      });
    },
  },
}
</script>
<style lang="scss" scoped>
  ::v-deep .van-popup{
    .van-share-sheet__options{
      .van-share-sheet__option{
        &:first-child{
          margin-left: 7px;
        }
        img {
          width: 44px;
          height: 44px;
          margin-left: 0;
          margin-right: 20px;
        }
        span {
          margin-right: 20px;
        }
      }
    }
    .van-share-sheet__cancel::before {
      height: 0px;
    }
  }
</style>
<style lang='scss' scoped>
  .noticeAnnouncement {
    clear: both;
    height: 100%;
    background-color: #F9F9F9;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    &.height60 {
      height: calc(100% - 44px)
    }
    // &.heightHF {
    //   height: calc(100% - 264px)
    // }
    .notice-cont {
      width: 100%;
      height: 100%;
      overflow: scroll;
      overflow-x: hidden;
      box-sizing: border-box;
      padding: 0 16px 75px 16px;
    }
    .noitce-item {
      padding: 16px;
      margin: 16px 0;
      border-bottom: 2px solid #F9F9F9;
      background: #FFFFFF;
      border-radius: 4px;
      .n-cont {
        display: flex;
        align-items: center;
        color: #666666;
        font-size: 14px;
        line-height: 14px;
        .n-point {
          width: 8px;
          height: 8px;
          background: #E21A1A;
          border-radius: 50%;
          margin-right: 6px;
        }
        .h-point {
          background-color: transparent;
        }
      }
      .n-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 16px;
        line-height: 22px;
        word-break: break-all;
        margin: 6px 0;
      }
      .n-main {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 15px;
        color: #999999;
        line-height: 21px;
        word-break: break-all;
      }
      .n-share {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 12px;
        color: #999999;
        line-height: 14px;
        font-size: 14px;
        div img{
          height: 13px;
          width: auto;
          margin-right: 6px;
        }
      }
      &:nth-last-child(1) {
        border: 0 none;
      }
    }
  }
  .rtop {
    margin-top: 61px;
  }
</style>